import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

window.scoremethod = "Qutoa";
window.scorename = " ";
window.scrollamount = 0;
window.vx = false;
window.quotatotal = "";
window.numberplayers = 0;
window.displayad = false;
window.displayhelp = false;
window.nogamedate = false;
window.coursename = " ";
window.coursedataname = '';
window.gaction = 0;
window.teamtotal = 0;
window.teamquota = 0;
window.teamscore = 0;
window.teetimearr=Array();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
