import React, {useState,} from "react";
import golfers from "../../../Data/golfers";
// import frontscores from "../../../Data/frontscores";
// import backscores from "../../../Data/backscores";
import "./ButtonControls.css";
import showHelp from "../../../Globals/ShowHelp";
import saveToLocalStorage from "../Library/SaveToLocalStorage";

const ButtonControls =({Clear, 
                        DisplayPlayer, 
                        DisplayAd, 
                        DisplayHelp}) => {
  const [ads, setAds,] = useState(false);
  const [redo, setRedo,] = useState(false);
  const [endgame, setEndGame,] = useState(false);
    
  var newTotals;

  const clearGolfers = () => {
    golfers.map((golfer) => (
      (golfer.golfername = ""),
      (golfer.skins =  ""), (golfer.quota =  ""),
      (golfer.total =  ""), (golfer.score =  ""),
      (golfer.golfername = ""),
      (golfer.h01 = ""), (golfer.h02 = ""), (golfer.h03 = ""),  
      (golfer.h04 = ""), (golfer.h05 = ""), (golfer.h06 = ""), 
      (golfer.h07 = ""), (golfer.h08 = ""), (golfer.h09 = ""),
      (golfer.h10 = ""), (golfer.h11 = ""), (golfer.h12 = ""),
      (golfer.h13 = ""), (golfer.h14 = ""), (golfer.h15 = ""),
      (golfer.h16 = ""), (golfer.h17 = ""), (golfer.h18 = "")
    ))
  };

  const onSave = (e) => {
    e.preventDefault();
    alert("Reserved for saving data to a server.");
  };

  const onDBload = (e) => {
    e.preventDefault();
    alert("Reserved for saving data to database.");
  };

  const onDBsave = (e) => {
    e.preventDefault();
    alert("Reserved for saving data to database.");
  };

    // const onAds = (e) => {
    //     e.preventDefault();
    //     DisplayAd();
    //   };

  const onLoad = (e) => {
      e.preventDefault();
      alert("Reserved for loading previous scores");
    };

  const onHelp = (e) => {
    e.preventDefault();
    console.log("1 display help " + " == "+ showHelp);
    DisplayHelp();
  };

const clearFrontGolferTotals = (() => {
  const frontGolferTotal=[];
  for (let ig=0; ig <4; ig++) {
    frontGolferTotal[ig] =  Number(golfers[ig].h01)  +
      Number(golfers[ig].h02)  + Number(golfers[ig].h03)  +
      Number(golfers[ig].h04)  + Number(golfers[ig].h05)  +
      Number(golfers[ig].h06)  + Number(golfers[ig].h07)  +
      Number(golfers[ig].h08)  + Number(golfers[ig].h09);  
  }
  console.log("frontgolfertotals " + JSON.stringify(frontGolferTotal));
});

const clearBackGolferTotals = (() => {
  const backGolferTotal=[];
  for (let ig=0; ig <4; ig++) {
    backGolferTotal[ig] =  Number(golfers[ig].h01)  +
      Number(golfers[ig].h02)  + Number(golfers[ig].h03)  +
      Number(golfers[ig].h04)  + Number(golfers[ig].h05)  +
      Number(golfers[ig].h06)  + Number(golfers[ig].h07)  +
      Number(golfers[ig].h08)  + Number(golfers[ig].h09);  
  }
  console.log("backgolfertotals " + JSON.stringify(backGolferTotal));
});

  const onClear = (e) => {
    e.preventDefault();
    clearGolfers();
    clearFrontGolferTotals();
    clearBackGolferTotals();
    // localStorage.setItem("game",JSON.stringify(golfers));
    saveToLocalStorage();
    console.log("ARRAY set " + JSON.stringify(golfers));
    newTotals = "";
    Clear(); 
  }

  return (
    <div>
      <div className="btnmenu-container">
        <div className="button-line">
          <div>
            <button
              className="btn"
              tabIndex={"-1" }
              onClick={(e) => onLoad(e)}
            > Load
            </button>
          </div>
          <div>
            <button
              tabIndex={"-1"}
              className="btn"
              onClick={(e) => onSave(e)}
            >
              Save
            </button>
          </div>
          <div>
            <button
              tabIndex={"-1"}
              className="btn"
              onClick={(e) => onClear(e)}
            > Clear
            </button>
          </div>
          <div>
            <button
              tabIndex={"-1"}
              className="btn"
              onClick={(e) => onDBload(e)}
            > DBload
            </button>
          </div>
          <div>
            <button
              tabIndex={"-1"}
              className="btn"
              onClick={(e) => onDBsave(e)}
            > DBsave
            </button>
          </div>
          <div>
            <button
              tabIndex={"-1"}
              className="btn"
              onClick={(e) => onHelp(e)}
            > Help
            </button>
          </div>
        </div>
      </div>
    </div>
  );

      // return (
      //   <div>
      //     <MyButtonsDisplay 
      //       onCourse={onCourse}
      //       onLoad={onLoad} 
      //       onSave={onSave}
      //       onClear={onClear} 
      //       onDBsave={onDBsave}
      //       onAds={onAds}
      //       onHelp={onHelp}
      //     />
      //   </div>
      // )
  };
export default ButtonControls;
/**
 * onCourse == makes sure titles are changed and golfers array is set
 * onLoad   == loads data from localstorage to golfers array 1st then tries dbstorage
 * onSave   == saves golfersarray to localstorage
 * onClear  == clears screen and resets golfers array
 * onAds    == a toggle button to display ads
 * onHelp   == a toggle to display help information
 */