import frontGolferTotal from "../../../Globals/FrontGolferTotals";
import frontHoleTotal from "../../../Globals/FrontHoleTotals";
import golfers from "../../../Data/golfers";

// console.log("fhTotals Before "  + JSON.stringify(frontHoleTotal));
const frontGolferTotals = () => {
  clearTotals();
  holeTotals();
  golferTotals();
  frontTotal();
  totalTotals()
  changeToBlanks();
 }

const clearTotals = () => {
  for (let g = 0; g < 10; g++) {
    frontHoleTotal[g] = 0;
  }

  for (let i = 0; i < 4; i++) {
    frontGolferTotal[i] = 0;
    golfers[i].ftotal = 0;
  }
};


const holeTotals = () => {
  for (let i = 0; i < 4; i++) {
    frontHoleTotal[0] = frontHoleTotal[0] + Number(golfers[i].h01);
    frontHoleTotal[1] = frontHoleTotal[1] + Number(golfers[i].h02);
    frontHoleTotal[2] = frontHoleTotal[2] + Number(golfers[i].h03);
    frontHoleTotal[3] = frontHoleTotal[3] + Number(golfers[i].h04);
    frontHoleTotal[4] = frontHoleTotal[4] + Number(golfers[i].h05);
    frontHoleTotal[5] = frontHoleTotal[5] + Number(golfers[i].h06);
    frontHoleTotal[6] = frontHoleTotal[6] + Number(golfers[i].h07);
    frontHoleTotal[7] = frontHoleTotal[7] + Number(golfers[i].h08);
    frontHoleTotal[8] = frontHoleTotal[8] + Number(golfers[i].h09);
  }
}

const golferTotals = () => {
  for (let i = 0; i < 4; i++) {
    frontGolferTotal[i] = 0;
    frontGolferTotal[i] = frontGolferTotal[i] + Number(golfers[i].h01);
    frontGolferTotal[i] = frontGolferTotal[i] + Number(golfers[i].h02);
    frontGolferTotal[i] = frontGolferTotal[i] + Number(golfers[i].h03);
    frontGolferTotal[i] = frontGolferTotal[i] + Number(golfers[i].h04);
    frontGolferTotal[i] = frontGolferTotal[i] + Number(golfers[i].h05);
    frontGolferTotal[i] = frontGolferTotal[i] + Number(golfers[i].h06);
    frontGolferTotal[i] = frontGolferTotal[i] + Number(golfers[i].h07);
    frontGolferTotal[i] = frontGolferTotal[i] + Number(golfers[i].h08);
    frontGolferTotal[i] = frontGolferTotal[i] + Number(golfers[i].h09);
    golfers[i].ftotal = frontGolferTotal[i];
  }
}

const frontTotal = () => {
  window.fholetotal = 0;
  frontHoleTotal[9] = 0;
  for (let i = 0; i < 4; i++) {
    window.fholetotal = window.fholetotal + golfers[i].ftotal;
    frontHoleTotal[9] = frontHoleTotal[9] + golfers[i].ftotal;
  }
}

const totalTotals = () => {
  for (let i = 0; i < 4; i++) {
    golfers[i].total = golfers[i].btotal + golfers[i].ftotal; 
  } 
}

const changeToBlanks = () => {
  for (let g = 0; g < 10; g++) {
    if (frontHoleTotal[g] === 0) {
      frontHoleTotal[g] = "";
    }
  }
  for (let i = 0; i < 4; i++) {
    if (frontGolferTotal[i] === 0) {
      frontGolferTotal[i] = "";
    }
    if (golfers[i].ftotal === 0) {
      golfers[i].ftotal="";
    }
    if (golfers[i].total === 0) {
      golfers[i].total="";
    }
  }
};

export default frontGolferTotals;
