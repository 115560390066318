import React from "react";
import "./Player.css";
import golfers from "../../Data/golfers";
import saveToLocalStorage from "../Utils/Library/SaveToLocalStorage";

const Team = ({DisplayPlayer}) => {
  const addTotals = () => {
    window.quotatotal = 0;
    window.numberplayers = 0
    for (let i = 0; i < 4; i++) {
      if (golfers[i].golfername.length >= 1) {
        window.quotatotal = window.quotatotal + Number(golfers[i].quota);
        window.numberplayers = window.numberplayers + 1;
        console.log("golferline "+i+"=>> " + golfers[i].golfername +
          " " + golfers[i].skins +
          " " + golfers[i].quota + 
          " " + window.numberplayers + "\n");
        saveToLocalStorage();
        DisplayPlayer();
      }
    };
    if (window.quotatotal === 0) {
      window.quotatotal = "";
    }
  }
  function handleNameChange(e) {
    e.preventDefault();
    golfers[e.target.id].golfername = e.target.value;
    addTotals();
  }

  function handleSkinsChange(e) {
    e.preventDefault();
    if (e.target.value !== "x") {
      e.target.value = "x";
    }
    golfers[e.target.id].skins = e.target.value;
    addTotals();
  }

  function handleQuotaChange(e) {
    e.preventDefault();
    if (isNaN(+e.target.value)) {
      e.target.value = "";
      alert("Must enter number.");
    }
    golfers[e.target.id].quota = e.target.value;
    addTotals();
  }

  // console.log("before player " + window.scrollamount);
  return (
    <div>
      <div className="center">
        <table id="players">
          <tbody>
            <tr>
              <th style={{ textAlign: "left", fontSize: "xx-small" }}>
                LAST NAME, FIRST NAME
              </th>
              <th>skins</th>
              <th>{window.scoremethod}</th>
            </tr>
            {golfers.map((golfer, index) => (
              <tr key={golfer.id}>
                <td>
                  <input
                    name="golfer"
                    value={golfer.golfername}
                    id={index}
                    type="text"
                    placeholder="Last NAME, First NAME"
                    style={{
                      margin: "0",
                      padding: "0",
                      width: "15.7rem",
                      height: "1.59em",
                      textAlign: "left",
                      fontSize: "small",
                      borderCollapse: "collapse",
                    }}
                    onChange={handleNameChange}
                  />
                </td>
                <td>
                  <input
                    name="skins"
                    value={golfer.skins}
                    id={index}
                    type="text"
                    size="1"
                    maxLength={1}
                    style={{ width: "1.8rem", textAlign: "center" }}
                    onChange={handleSkinsChange}
                  />
                </td>
                <td>
                  <input
                    name="quota"
                    value={golfer.quota}
                    id={index}
                    type="text"
                    inputMode="numeric"
                    size="2"
                    min="0"
                    maxLength="2"
                    max="99"
                    pattern="[0-9]{2}"
                    required
                    style={{ width: "2.0em", textAlign: "center" }}
                    onChange={handleQuotaChange}
                  />
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={"2"}>
                <input
                  readOnly
                  style={{
                    width: "17.65rem",
                    height: "20px",
                    textAlign: "left",
                    fontSize: "14px",
                    backgroundColor: "#d3f8d3",
                  }}
                  tabIndex="-1"
                  value=" Total =======>>>"
                />
              </td>
              <td>
                <input
                  // Quotas
                  readOnly
                  style={{
                    width: "2.0em",
                    height: "20px",
                    backgroundColor: "#d3f8d3",
                  }}
                  tabIndex="-1"
                  value={window.quotatotal}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Team;
