import "./Help.css";

const Help = () => {
  if (window.displayhelp) {
    return (
      <div className="helpdiv">
        <div>
          <p className="helpcenter">
            Help - for keeping an electronic score using the EZ-Score App.
            <br />
            EZ-Score can keep score for Stableford, Stroke or Match Play.
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;HELP and Special button are toggled for on/off.
            You must enter a player name to have the App keep score for you.
            {/* <br />
            <br />
            &nbsp;&nbsp;&nbsp;EZ-Score can keep score for Stableford, Stroke or
            Match Play. */}
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;This "demo" is set-up for play at St. Andrews
            using the Stableford scoring method. Scoring for this modified
            version of stableford is: &nbsp;&nbsp;&nbsp;Albatross = 16; Eagle =
            8; <br />
            &nbsp;&nbsp;&nbsp;Birdie = 4; Par = 2; bogie = 1; <br />
            &nbsp;&nbsp;&nbsp;double bogie or more = 0.
            <br />
            You may use 40 minus your USGA index for your quota.
          </p>
        </div>
      </div>
    );
  } else {
    return;
  }
};
export default Help;
