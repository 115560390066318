import "./StAndrews.css";
import React, { useState } from "react";
import special from "../StAndrews/Images/GreensideGrillMenu.png";
import ReactBlinkText from "../../Utils/UI/BlinkTag";

const StAndrews = ({ ShowAd }) => {
  let currentMessage =
    "$7 Combo: Hot Dog or Brat with Bag of Chips or 32oz. Fountain  Drink";
  if (window.displayad === true) {
    ShowAd();
  }
  if (window.displayad === false) {
    // ShowAd();
    return;
  }
  // window.scrollTo(0, 370);
  return (
    <div>
      <div
        className="center"
        style={{
          paddingTop: "0.10em",
          marginTop: "0.5em",
          marginBottom: "0.5em",
          width: "20rem",
        }}
      >
        <div className="allOfAd">
          <div className="andrewmenu">
            <img src={special} alt="reserved for ad space" />
          </div>
          <div
            style={{
              backgroundColor: "lightgray",
              paddingTop: "8px",
              marginTop: "-4px",
              height: "fitContent",
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px",
            }}
          >
            <div className="turnspecial">
              <span style={{ float: "left" }}>Menu Special</span> &nbsp; &nbsp;
              &nbsp;
              <ReactBlinkText text="At-The-Turn " />
            </div>
            <div className="turnitem">
              <p
                style={{
                  margin: "0",
                  paddingLeft: "1.0em",
                  paddingRight: "1.0em",
                  textAlign: "center",
                  color: "green",
                  fontWeight: "bolder",
                }}
              >
                {currentMessage}
              </p>
            </div>
            <div className="phonenow">Call! 913-530-4925 or</div>
            <div className="phonenumber">
              <a href="tel:913-685-9470">Click to call ORDER NOW!</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StAndrews;
