// import { useState } from "react";
import golfers from "../../../Data/golfers";
import ReloadPage from "./ReloadPage";

const reloadGolfers = (data) =>{
  const lsgolfer = JSON.parse(data);
  for (let g=0; g<4; g++) {
    golfers[g].id = lsgolfer[g].id;
    golfers[g].gamedate = lsgolfer[g].gamedate;
    golfers[g].coursename = lsgolfer[g].coursename;
    golfers[g].teetime = lsgolfer[g].teetime;
    golfers[g].scoremethod = lsgolfer[g].scoremethod;
    golfers[g].golfername = lsgolfer[g].golfername;
    golfers[g].skins = lsgolfer[g].skins;
    golfers[g].quota = lsgolfer[g].quota;
    golfers[g].h01 = lsgolfer[g].h01;
    golfers[g].h02 = lsgolfer[g].h02;
    golfers[g].h03 = lsgolfer[g].h03;
    golfers[g].h04 = lsgolfer[g].h04;
    golfers[g].h05 = lsgolfer[g].h05;
    golfers[g].h06 = lsgolfer[g].h06;
    golfers[g].h07 = lsgolfer[g].h07;
    golfers[g].h08 = lsgolfer[g].h08;
    golfers[g].h09 = lsgolfer[g].h09;
    golfers[g].h10 = lsgolfer[g].h10;
    golfers[g].h11 = lsgolfer[g].h11;
    golfers[g].h12 = lsgolfer[g].h12;
    golfers[g].h13 = lsgolfer[g].h13;
    golfers[g].h14 = lsgolfer[g].h14;
    golfers[g].h15 = lsgolfer[g].h15;
    golfers[g].h16 = lsgolfer[g].h16;
    golfers[g].h17 = lsgolfer[g].h17;
    golfers[g].h18 = lsgolfer[g].h18;
    golfers[g].total = lsgolfer[g].total;
    golfers[g].score = lsgolfer[g].score;
  }
  // ReloadPage();
}

export default reloadGolfers;