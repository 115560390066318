import globalAdvertiser from "../../../Globals/GlobalAd";
import StAndrews from "../StAndrews/StAndrews";
import OPSykesLady from "../OPSykesLady/OPSykesLady";

const ControlAds = ({ ShowAd }) => {
  if (globalAdvertiser.includes("Andrews")) {
    return (
      <div>
        <StAndrews ShowAd={ShowAd} />
      </div>
    );
  } else if (globalAdvertiser.includes("Sykes")) {
    return (
      <div>
        <OPSykesLady ShowAd={ShowAd} />
      </div>
    );
  } else {
    return;
  }
};
export default ControlAds;
