import React, { useState, useEffect, useRef } from "react";
import "./BackNine.css";
import golfers from "../../Data/golfers";
import Pars from "../../Globals/Pars";
import backGolferTotal from "../../Globals/BackGolferTotals";
import backHoleTotal from "../../Globals/BackHoleTotals";
import backTotals from "../../Globals/BackTotals";
import backGolferTotals from "../Utils/Library/BackGolferTotals";
import saveToLocalStorage from "../Utils/Library/SaveToLocalStorage";

const BackNine = ({
  ShowAd, DisplayAd, bakH10Ref,
  bakH11Ref, bakH12Ref, bakH13Ref, bakH14Ref,
  bakH15Ref, bakH16Ref, bakH17Ref, bakH18Ref,
}) => {
  const [inputs, setInputs] = useState("[]");
  const [redo, setRedo] = useState(false);
  const [keyPress, setKeyPress] = useState("0");

  useEffect(() => {
    backGolferTotals();
    // window.scrollamount = 120;
    // console.log("front hole totals " + JSON.stringify(backGolferTotal))
  },[]);

  const keyPressHandler = (e) => {
    e.preventDefault();
    var nextfield;
    var Id = e.target.id;
    var hole = e.target.name;
    backGolferTotals();
    setKeyPress(Id); // must have a defined
    console.log("hole is " + hole);
  };

  const changeHandler = (e) => {
    var name = e.target.name;
    var ovalue = e.target.value;
    var value = e.target.value;
    var tid = e.target.id;
    var playerid = Number(tid) + 1;
    if (golfers[tid].golfername.length === 0) {
      e.target.value = "";
      return;
    }
    if (isNaN(+value)) {
      value = "0";
      alert(
        "NOT A NUMBER VALUE SET TO ZERO ==> " + name + " " + tid + " " + ovalue
      );
      e.target.value = value;
    }
    if (name === "h10") golfers[tid].h10 = value;
    if (name === "h11") golfers[tid].h11 = value;
    if (name === "h12") golfers[tid].h12 = value;
    if (name === "h13") golfers[tid].h13 = value;
    if (name === "h14") golfers[tid].h14 = value;
    if (name === "h15") golfers[tid].h15 = value;
    if (name === "h16") golfers[tid].h16 = value;
    if (name === "h17") golfers[tid].h17 = value;
    if (name === "h18") golfers[tid].h18 = value;
    window.displayad = false;
    if ((name === "h17") && (playerid === window.numberplayers) ) {
      window.displayad = true;
      window.gaction = 0;
      ShowAd();
    } else {
      window.displayad = true;
      window.gaction = 0;
      DisplayAd();
    }
    backGolferTotals();
    saveToLocalStorage();    
    setRedo(!redo);
  };

const inputStyle = {
  border: "1px solid red"
}

// console.log("before backnine " + window.scrollamount);

  return (
    <div>
      <div className="center">
        <table
          id="backscores"
          style={{
            // width: "16.9%",
            width: "20.0rem",
            marginLeft: "auto",
            marginRight: "auto",
            border: "3px solid purple",
          }}
        >
          <tbody>
            <tr>
              <th colSpan="2" style={{ fontSize: "0.65em" }}>
                Back Nine
              </th>
              <th>H10</th>
              <th>H11</th>
              <th>H12</th>
              <th>H13</th>
              <th>H14</th>
              <th>H15</th>
              <th>H16</th>
              <th>H17</th>
              <th>H18</th>
              <th>Total</th>
            </tr>
            <tr id="fontpars">
              <th colSpan="2">
                {Pars["backcourse"]}
              </th>
              <th>{Pars[10]}</th>
              <th>{Pars[11]}</th>
              <th>{Pars[12]}</th>
              <th>{Pars[13]}</th>
              <th>{Pars[14]}</th>
              <th>{Pars[15]}</th>
              <th>{Pars[16]}</th>
              <th>{Pars[17]}</th>
              <th>{Pars[18]}</th>
              <th>{Pars["btot"]}</th>
            </tr>
            {golfers.map((golfer, index) => (
              <tr key={golfer.id}>
                <td colSpan="2">
                  <input
                    readOnly
                    style={{
                      width: "4.90rem",
                      textAlign: "left",
                      backgroundColor: "#d3f8d3",
                    }}
                    tabIndex="-1"
                    value={golfers[index].golfername}
                  />
                </td>
                <td>
                  <input
                    name="h10"
                    value={golfers[index].h10}
                    id={index}
                    ref={(el) => {
                      bakH10Ref.current[index] = el;
                    }}
                    type="text"
                    inputMode="numeric"
                    size="2"
                    min="0"
                    maxLength="2"
                    max="99"
                    pattern="[0-9]{2}"
                    style={{
                      textAlign: "center",
                    }}
                    onBlur={keyPressHandler}
                    onChange={changeHandler}
                  />
                </td>
                <td>
                  <input
                    name="h11"
                    value={golfers[index].h11}
                    id={index}
                    ref={(e2) => {
                      bakH11Ref.current[index] = e2;
                    }}
                    type="text"
                    inputMode="numeric"
                    size="2"
                    min="0"
                    maxLength="2"
                    max="99"
                    pattern="[0-9]{2}"
                    style={{ textAlign: "center", inputs:"style" }}
                    onBlur={keyPressHandler}
                    onChange={changeHandler}
                  />
                </td>
                <td>
                  <input
                    name="h12"
                    value={golfers[index].h12}
                    id={index}
                    ref={(e2) => {
                      bakH12Ref.current[index] = e2;
                    }}
                    type="text"
                    inputMode="numeric"
                    size="2"
                    min="0"
                    maxLength="2"
                    max="99"
                    pattern="[0-9]{2}"
                    style={{ textAlign: "center" }}
                    onBlur={keyPressHandler}
                    onChange={changeHandler}
                  />
                </td>
                <td>
                  <input
                    name="h13"
                    value={golfers[index].h13}
                    id={index}
                    ref={(e2) => {
                      bakH13Ref.current[index] = e2;
                    }}
                    type="text"
                    inputMode="numeric"
                    size="2"
                    min="0"
                    maxLength="2"
                    max="99"
                    pattern="[0-9]{2}"
                    style={{ textAlign: "center" }}
                    onBlur={keyPressHandler}
                    onChange={changeHandler}
                  />
                </td>
                <td>
                  <input
                    name="h14"
                    value={golfers[index].h14}
                    id={index}
                    ref={(e2) => {
                      bakH14Ref.current[index] = e2;
                    }}
                    type="text"
                    inputMode="numeric"
                    size="2"
                    min="0"
                    maxLength="2"
                    max="99"
                    pattern="[0-9]{2}"
                    style={{ textAlign: "center" }}
                    onBlur={keyPressHandler}
                    onChange={changeHandler}
                  />
                </td>
                <td>
                  <input
                    name="h15"
                    value={golfers[index].h15}
                    id={index}
                    ref={(e2) => {
                      bakH15Ref.current[index] = e2;
                    }}
                    type="text"
                    inputMode="numeric"
                    size="2"
                    min="0"
                    maxLength="2"
                    max="99"
                    pattern="[0-9]{2}"
                    style={{ textAlign: "center" }}
                    onBlur={keyPressHandler}
                    onChange={changeHandler}
                  />
                </td>
                <td>
                  <input
                    name="h16"
                    value={golfers[index].h16}
                    id={index}
                    ref={(e2) => {
                      bakH16Ref.current[index] = e2;
                    }}
                    type="text"
                    inputMode="numeric"
                    size="2"
                    min="0"
                    maxLength="2"
                    max="99"
                    pattern="[0-9]{2}"
                    style={{ textAlign: "center" }}
                    onBlur={keyPressHandler}
                    onChange={changeHandler}
                  />
                </td>
                <td>
                  <input
                    name="h17"
                    value={golfers[index].h17}
                    id={index}
                    ref={(e2) => {
                      bakH17Ref.current[index] = e2;
                    }}
                    type="text"
                    inputMode="numeric"
                    size="2"
                    min="0"
                    maxLength="2"
                    max="99"
                    pattern="[0-9]{2}"
                    style={{ textAlign: "center" }}
                    onBlur={keyPressHandler}
                    onChange={changeHandler}
                  />
                </td>
                <td>
                  <input
                    name="h18"
                    value={golfers[index].h18}
                    id={index}
                    ref={(e2) => {
                      bakH18Ref.current[index] = e2;
                    }}
                    type="text"
                    inputMode="numeric"
                    size="2"
                    min="0"
                    maxLength="2"
                    max="99"
                    pattern="[0-9]{2}"
                    style={{ textAlign: "center" }}
                    onBlur={keyPressHandler}
                    onChange={changeHandler}
                  />
                </td>
                <td>
                  <input
                    readOnly
                    type="text"
                    id={index}
                    total="total"
                    style={{ width: "2.4em", backgroundColor: "#d3f8d3" }}
                    tabIndex="-1"
                    // value={backscores[index].total}
                    value={backGolferTotal[index]}
                  />
                </td>
              </tr>
            ))}
            <tr style={{ height: "1.5" }}>
              <td colSpan="2" className="outside">
                <input
                  readOnly
                  style={{
                    width: "4.90rem",
                    textAlign: "center",
                    backgroundColor: "#d3f8d3",
                  }}
                  tabIndex="-1"
                  value="Totals ==>"
                />
              </td>
              <td className="outside">
                <input
                  readOnly
                  style={{ backgroundColor: "#d3f8d3" }}
                  tabIndex="-1"
                  value={backHoleTotal[0]}
                />
              </td>
              <td className="outside">
                <input
                  readOnly
                  style={{ backgroundColor: "#d3f8d3" }}
                  tabIndex="-1"
                  value={backHoleTotal[1]}
                />
              </td>
              <td className="outside">
                <input
                  readOnly
                  style={{ backgroundColor: "#d3f8d3" }}
                  tabIndex="-1"
                  value={backHoleTotal[2]}
                />
              </td>
              <td className="outside">
                <input
                  readOnly
                  style={{ backgroundColor: "#d3f8d3" }}
                  tabIndex="-1"
                  value={backHoleTotal[3]}
                />
              </td>
              <td className="outside">
                <input
                  readOnly
                  style={{ backgroundColor: "#d3f8d3" }}
                  tabIndex="-1"
                  value={backHoleTotal[4]}
                />
              </td>
              <td className="outside">
                <input
                  readOnly
                  style={{ backgroundColor: "#d3f8d3" }}
                  tabIndex="-1"
                  value={backHoleTotal[5]}
                />
              </td>
              <td className="outside">
                <input
                  readOnly
                  style={{ backgroundColor: "#d3f8d3" }}
                  tabIndex="-1"
                  value={backHoleTotal[6]}
                />
              </td>
              <td className="outside">
                <input
                  readOnly
                  style={{ backgroundColor: "#d3f8d3" }}
                  tabIndex="-1"
                  value={backHoleTotal[7]}
                />
              </td>
              <td className="outside">
                <input
                  readOnly
                  style={{ backgroundColor: "#d3f8d3" }}
                  tabIndex="-1"
                  value={backHoleTotal[8]}
                />
              </td>
              <td className="outside">
                <input
                  readOnly
                  tabIndex="-1"
                  value ={backHoleTotal[9]}
                  style={{ width: "2.4em", backgroundColor: "#d3f8d3" }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BackNine; //exporting a component make it reusable and this is the beauty of react
