import golfers from "../../../Data/golfers";
import teamscores from "../../../Data/teamscores";
import backGolferTotals from "./BackGolferTotals";
import frontGolferTotals from "./FrontGolferTotals";


const setGlobals = () => {
  window.coursename = golfers[0].coursename;
  window.coursedataname = golfers[0].coursename.replace(/. /g,"");

  // console.log("seGlobals " + gamedate);
  // if (gamedate === "game") {
    // window.teamtotal = " ";
    // window.teamquota = " ";
    // window.teamscore = " ";
    for (let i = 0; i < 4; i++) {
      golfers[i].ftotal =" ";
      golfers[i].btotal =" ";
      golfers[i].total =" ";
      golfers[i].score =" ";
    }
  //   return;
  // }
  frontGolferTotals();
  backGolferTotals();
  window.teamtotal = 0;
  window.teamquota = 0;
  window.teamscore = 0;
    for (let i = 0; i < 4; i++) {
      if (golfers[i].btotal === 0){golfers[i].btotal =" ";}
      if (golfers[i].ftotal === 0){golfers[i].ftotal =" ";}
      golfers[i].total = Number(golfers[i].ftotal) + Number(golfers[i].btotal);
      golfers[i].score = Number(golfers[i].total) - Number(golfers[i].quota);
      if (golfers[i].total === 0){golfers[i].total =" ";}
      if (golfers[i].score === 0){golfers[i].score =" ";}
      teamscores[i].totals = teamscores[i].totals + Number(golfers[i].total);
      teamscores[i].quotas = teamscores[i].quotas + Number(golfers[i].quota);
      teamscores[i].scores = teamscores[i].totals - Number(golfers[i].quota);
      // golfers[i].score = teamscores[i].scores;
      // console.log("golfertotal >>>" + golfers[i].total + "<<<"+"\n");
      // console.log("golferscore >>>" + golfers[i].score + "<<<"+"\n");
      window.teamtotal = window.teamtotal + Number(golfers[i].total);
      window.teamquota = window.teamquota + Number(golfers[i].quota);
      window.teamscore = window.teamtotal - window.teamquota;
    }
    for (let i=0; i<4;i++) {
      if (teamscores[i].totals === 0) { teamscores[i].totals = " "; }
      if (teamscores[i].quotas === 0) { teamscores[i].quotas = " "; }
      if (teamscores[i].scores === 0) { teamscores[i].scores = " "; }
      if (window.teamtotal === 0) { window.teamtotal = " "; }
      if (window.teamquota === 0) { window.teamquota = " "; }
      if (window.teamscore === 0) { window.teamscore = " "; }
    }
    // console.log("PLAYERS window " + (window.teamscore));
    // console.log("PLAYERS total " + JSON.stringify(golfers));
  };

  export default setGlobals;