import React, { useState, useRef,  useEffect } from "react";
import { useScrollTo } from "./components/Mobile/Utils/UI/useScrollTo";
import "./App.css";
// basic data 
import golfers from "./components/Data/golfers";
import teamscores from "./components/Data/teamscores";
// code
import Heading from "./components/UI/Heading/Heading";
import Course from "./components/Mobile/Courses/Course";
import ButtonControls from "./components/Mobile/Utils/UI/ButtonControls";
import Team from "./components/Mobile/Players/Player";
import ControlAds from "./components/Mobile/Ads/ControlAds/ControlAds";
import FrontNine from "./components/Mobile/Scores/FrontNine";
import BackNine from "./components/Mobile/Scores/BackNine"
import Totals from "./components/Mobile/Scores/TotalScores";
import Footer from "./components/Mobile/Utils/Library/Footer";
import Help from "./components/Mobile/Help/Help";
import newScoreMethod from "./components/Mobile/Utils/Library/newScoreMethod";
import reloadGolfers from "./components/Mobile/Utils/Library/ReloadGolfers";
import setRandomIds from "./components/Mobile/Utils/Library/SetRandomIds";
//globals
import setGlobals from "./components/Mobile/Utils/Library/SetGlobals"
import frontGolferTotals from "./components/Mobile/Utils/Library/FrontGolferTotals";
import frontGolferTotal from "./components/Globals/FrontGolferTotals";
import frontHoleTotal from "./components/Globals/FrontHoleTotals";
import frontTotals from "./components/Globals/FrontTotals";
import backGolferTotal from "./components/Globals/BackGolferTotals";
import backGolferTotals from "./components/Mobile/Utils/Library/BackGolferTotals";
import backHoleTotal from "./components/Globals/BackHoleTotals";
import backTotals from "./components/Globals/BackTotals";
import saveToLocalStorage from "./components/Mobile/Utils/Library/SaveToLocalStorage";

const App = () => { 
  const [redo, setRedo] = useState( true );
  // console.log("first here " + redo);
  const gamedate="game"+golfers[0].gamedate;
  // console.log("first gamedate >>>" + gamedate + "<<<");
  setGlobals();
/////////////////////////////////////////////
  // useEffect(() => {
  //   const data = localStorage.getItem(gamedate);
  //   setGlobals();
  //   console.log("localstorage " + data + " = " +window.scrollamount);
  //   if ( data !== null ) {
  //     reloadGolfers(data);
  //     newScoreMethod(golfers[0].scoremethod);
  //     // todo add team and globals here
  //     frontGolferTotals();
  //     backGolferTotals();
  //     saveToLocalStorage()
  //     DisplayPlayer();
  //   } else {
  //     window.scorename = " ";
  //     window.nogamedate = true;
  //     setRandomIds();
  //   }  
  // },[]);
  /////////////////////////////////////////
  // useEffect(() => {
  //   if (!(gamedate === "game")) {
  //     console.log("test gamedate " + gamedate);
  //     console.log(redo)
  //     if (window.nogamedate !== true) {
  //     localStorage.setItem(gamedate, JSON.stringify(golfers));
  //     }
  //   }
  // },[]);
  const frtH01Ref = useRef([]);  const frtH02Ref = useRef([]);
  const frtH03Ref = useRef([]);  const frtH04Ref = useRef([]);
  const frtH05Ref = useRef([]);  const frtH06Ref = useRef([]);
  const frtH07Ref = useRef([]);  const frtH08Ref = useRef([]);
  const frtH09Ref = useRef([]);

  const bakH10Ref = useRef([]);  const bakH11Ref = useRef([]);
  const bakH12Ref = useRef([]);  const bakH13Ref = useRef([]);
  const bakH14Ref = useRef([]);  const bakH15Ref = useRef([]);
  const bakH16Ref = useRef([]);  const bakH17Ref = useRef([]);
  const bakH18Ref = useRef([]);

  const golferSkinsRef = useRef([]);  const golferQuotaRef = useRef([]);
  const golferTotalRef = useRef([]);  const golferScoreRef = useRef([]);
// begin here
// console.log("begin here " + window.scrollamount);
  useScrollTo(0,window.scrollamount)
// go display beginning screen
// functions begin here
  const Clear = () => {
    console.log( "CLEARED " );
    clearCourse();
    clearFront();
    clearFrontTotals();
    clearBack();
    clearTotal();
    setRedo(!redo);
  };

const clearCourse = () => {
  for (let i=0; i<4;i++) {
    golfers[i].coursename = "";
    golfers[i].date = "";
    golfers[i].teetime = "";
    golfers[i].scoremethod = "";
  }
};

  const clearFrontTotals = () => {
    // frontGolferTotal=[];
    for (let i=0; i<4; i++) {
    console.log("App front totals" + frontGolferTotal[i] + "\n");
    }
  }

  const clearFront = () => {
      // for (let i = 0; i < 4; i++) {
      //   frtH01Ref.current[i].value =""; frtH02Ref.current[i].value ="";
      //   frtH03Ref.current[i].value =""; frtH04Ref.current[i].value ="";
      //   frtH05Ref.current[i].value =""; frtH06Ref.current[i].value ="";
      //   frtH07Ref.current[i].value =""; frtH08Ref.current[i].value ="";
      //   frtH09Ref.current[i].value ="";
      // }
      for (let h = 0; h < 10; h++) {
        frontHoleTotal[h] = "";
      }
      for (let g = 0; g < 4; g++) {
        frontGolferTotal[g] = "";
      }
      frontTotals[0] = "";
      // DisplayPlayer();
  };

  const clearBack = () => {
    // for (let i = 0; i < 4; i++) {
    //   bakH10Ref.current[i].value =""; bakH11Ref.current[i].value ="";
    //   bakH12Ref.current[i].value =""; bakH13Ref.current[i].value ="";
    //   bakH14Ref.current[i].value =""; bakH15Ref.current[i].value ="";
    //   bakH16Ref.current[i].value =""; bakH17Ref.current[i].value ="";
    //   bakH18Ref.current[i].value ="";
    // }
    for (let h = 0; h < 10; h++) {
      backHoleTotal[h] = "";
    }
    for (let g = 0; g < 4; g++) {
      backGolferTotal[g] = "";
    }
    backTotals[0] = "";
  };

  const clearTotal = () => {
    for ( let i = 0; i < 4; i++ ) {
      teamscores[i].totals = "";
      teamscores[i].quotas = "";
      teamscores[i].scores = "";
    }
  }

  const ShowAd =() => {
      if ( window.displayad === true && 
           window.gaction === 0 ) {
        setRedo(!redo );
        window.gaction = 1;
      }
      if ( window.gaction ===  6 ) {
        setRedo(!redo);
      }
    }

  const DisplayPlayer = () => {
    window.scrollamount =  500;
    setRedo(!redo);
  };

  const DisplayAd = () => {
      window.displayad = !window.displayad;
      setRedo(!redo);
  };

  const DisplayHelp = () => {
    window.displayhelp = !window.displayhelp;
    window.scrollamount = 250;
    setRedo(!redo);
  };
// console.log("before screen display "+window.scrollamount);
  return (
    <div>
      <div>
        <div id="tswcontainer">
          <div id="tswcontent">
            <div id="tswcontproper">
              <Heading />
              <Course DisplayPlayer={DisplayPlayer} />
              <ButtonControls
                Clear={Clear}
                DisplayPlayer={DisplayPlayer}
                DisplayAd={DisplayAd}
                DisplayHelp={DisplayHelp}
              />
              <ControlAds
                ShowAd={ShowAd}
              />
              <Help
                DisplayHelp={DisplayHelp}
              />
              <Team DisplayPlayer={DisplayPlayer}
              />
              <FrontNine
                ShowAd={ShowAd}
                DisplayAd={DisplayAd}
                DisplayPlayer={DisplayPlayer}
                frtH01Ref={frtH01Ref} frtH02Ref={frtH02Ref}
                frtH03Ref={frtH03Ref} frtH04Ref={frtH04Ref}
                frtH05Ref={frtH05Ref} frtH06Ref={frtH06Ref}
                frtH07Ref={frtH07Ref} frtH08Ref={frtH08Ref}
                frtH09Ref={frtH09Ref}
              />
              <BackNine
                ShowAd={ShowAd}
                DisplayAd={DisplayAd}
                DisplayPlayer={DisplayPlayer}
                bakH10Ref={bakH10Ref} bakH11Ref={bakH11Ref}
                bakH12Ref={bakH12Ref} bakH13Ref={bakH13Ref}
                bakH14Ref={bakH14Ref} bakH15Ref={bakH15Ref}
                bakH16Ref={bakH16Ref} bakH17Ref={bakH17Ref}
                bakH18Ref={bakH18Ref}
              />
              <Totals
                DisplayPlayer={DisplayPlayer}
                golferSkinsRef={golferSkinsRef}
                golferTotalRef={golferTotalRef}
                golferQuotaRef={golferQuotaRef}
                golferScoreRef={golferScoreRef}
              />
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;

  // if(window.coursedataname.length > 0) {
  //   console.log("print from apps " + window.coursedataname);
  //   // LoadComponent(window.coursename);
  //   // require('/components/Data/CourseInfo/StAndrews/par').default;
  //   console.log("pars " + JSON.stringify(par));
  // }
