const golfers =
  [
    {
      id: 1, gamedate: "",coursename: "", teetime: "", scoremethod: "",
      golfername: "", skins: "", quota: "",
      h01: "", h02: "", h03: "", h04: "", h05: "", h06: "", h07: "", h08: "", h09: "",
      h10: "", h11: "", h12: "", h13: "", h14: "", h15: "", h16: "", h17: "", h18: "",
    },
    {
      id: 2, gamedate: "",coursename: "", teetime: "", scoremethod: "",
      golfername: "", skins: "", quota: "",
      h01: "", h02: "", h03: "", h04: "", h05: "", h06: "", h07: "", h08: "", h09: "",
      h10: "", h11: "", h12: "", h13: "", h14: "", h15: "", h16: "", h17: "", h18: "",
    },
    {
      id: 3, gamedate: "",coursename: "", teetime: "", scoremethod: "",
      golfername: "", skins: "", quota: "",
      h01: "", h02: "", h03: "", h04: "", h05: "", h06: "", h07: "", h08: "", h09: "",
      h10: "", h11: "", h12: "", h13: "", h14: "", h15: "", h16: "", h17: "", h18: "",
    },
    {
      id: 4, gamedate: "",coursename: "", teetime: "", scoremethod: "",
      golfername: "", skins: "", quota: "",
      h01: "", h02: "", h03: "", h04: "", h05: "", h06: "", h07: "", h08: "", h09: "",
      h10: "", h11: "", h12: "", h13: "", h14: "", h15: "", h16: "", h17: "", h18: "",
    },
  ];
export default golfers;
