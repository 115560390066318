import golfers from "../../../Data/golfers";
import { v4 as uuidv4 } from 'uuid';

const setRandomIds = ()  => {
    for (var i=0; i < 4; i++) {
      if (golfers[i].id.length === 8){continue};
      golfers[i].id = uuidv4().slice(0,8); 
    }
  }

  export default setRandomIds;