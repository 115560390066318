import React from "react";
import classes from "./Heading.module.css";
// console.log("before heading " + window.scrollamount);
const Heading =
  () => {
    return (
      <div id="tswcontent">
        <div
          className={
            classes.heading
          }
        >
          EZ
          -
          Score{" "}
          <br />
          <span
            style={{ fontSize: "small", fontWeight: "normal", backgroundColor: "green",
              WebkitTextStroke:"0px gold", borderRadius: "5px", padding:"0px 5px",
            }}
          >
            v-0.07
          </span>
        </div>
      </div>
    );
  };

export default Heading;
