import "./TotalScores.css";
import React from "react";
import golfers from "../../Data/golfers";
import teamscores from "../../Data/teamscores";
import setGlobals from "../Utils/Library/SetGlobals";
import saveToLocalStorage from "../Utils/Library/SaveToLocalStorage";

const Totals = () => {

  setGlobals();
  saveToLocalStorage();

  return (
    <div>
      <div className="center">
        <table id="golfers">
          <tbody>
            <tr>
              <th style={{ width: "11.2em" }}>Player SCORES</th>
              <th>skins</th>
              <th style={{ fontSize: "8px" }}>TOTALS</th>
              <th>{window.scoremethod}</th>
              <th>Score</th>
            </tr>
            {teamscores.map((teamscore,index) => (
            <tr key ={teamscore.id}>
                <td className="scores-golfer">
                  <input
                    style={{
                      width: "11.10rem",
                      height: "1.59em",
                      textAlign: "left",
                    }}
                    className="scores-name"
                    name="golfer"
                    id={index}
                    readOnly
                    defaultValue={golfers[index].golfername}
                    type="text"
                    tabIndex="-1"
                  />
                </td>
                <td>
                  <input
                    readOnly
                    style={{
                      width: "1.6em",
                      textAlign: "center",
                      backgroundColor: "#d3f8d3",
                    }}
                    name="skins"
                    defaultValue={golfers[index].skins}
                    // id={index}
                    type="text"
                    size="1"
                    maxLength={1}
                    tabIndex="-1"
                  />
                </td>
                <td>
                  <input
                    readOnly
                    name="totals"
                    id={index}
                    style={{
                      width: "2.5em",
                      textAlign: "center",
                      backgroundColor: "#d3f8d3",
                    }}
                    value={golfers[index].total}
                    type="text"
                    tabIndex="-1"
                  />
                </td>                
                <td>
                  <input
                    readOnly
                    style={{
                      width: "2.3em",
                      textAlign: "center",
                      paddingRight: "2px",
                      backgroundColor: "#d3f8d3",
                    }}
                    name="quota"
                    value={golfers[index].quota}
                    // id={index}
                    type="text"
                    tabIndex="-1"
                  />
                </td>
                <td>
                  <input
                    readOnly
                    id={index}
                    style={{
                      width: "2.3em",
                      textAlign: "center",
                      backgroundColor: "#d3f8d3",
                    }}
                    tabIndex="-1"
                    value={golfers[index].score}
                  />
                </td>
            </tr>
            ))}
            <tr>
              <td colSpan="2">
                <input
                  readOnly
                  style={{
                    width: "12.60rem",
                    height: "20px",
                    textAlign: "left",
                    fontSize: "14px",
                    backgroundColor: "#d3f8d3",
                  }}
                  tabIndex="-1"
                  value=" Total ===========>"
                />
              </td>
              <td>
                <input
                  // Totals
                  readOnly
                  style={{
                    width: "2.5em",
                    height: "20px",
                    backgroundColor: "#d3f8d3",
                  }}
                  tabIndex="-1"
                  value={window.teamtotal}
                />
              </td>              
              <td>
                <input
                  // Quotas
                  readOnly
                  style={{
                    width: "2.3em",
                    height: "20px",
                    backgroundColor: "#d3f8d3",
                    textAlign: "center",
                  }}
                  tabIndex="-1"
                  value={window.teamquota}
                />
              </td>
              <td>
                <input
                  // Socre
                  readOnly
                  style={{
                    width: "2.3em",
                    height: "20px",
                    backgroundColor: "#d3f8d3",
                  }}
                  tabIndex="-1"
                  value={window.teamscore}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Totals;
