import React, { useState,useEffect } from "react";
import "./FrontNine.css";
import golfers from "../../Data/golfers";
import Pars from "../../Globals/Pars";
import frontHoleTotal from "../../Globals/FrontHoleTotals";
import frontGolferTotal from "../../Globals/FrontGolferTotals";
import frontGolferTotals from "../Utils/Library/FrontGolferTotals";
import saveToLocalStorage from "../Utils/Library/SaveToLocalStorage";
import classNames from "classnames";

const FrontNine = ({
  ShowAd, DisplayAd, frtH01Ref,
  frtH02Ref,  frtH03Ref, frtH04Ref,  frtH05Ref,
  frtH06Ref,  frtH07Ref, frtH08Ref,  frtH09Ref,
}) => {
  //   const gamedate="game"+golfers[0].gamedate;
  //   const [inputs, setInputs] = useState(() => {
  //   const saved = localStorage.getItem(gamedate);
  //   const initialValue = JSON.parse(saved);
  //   return initialValue || "";
  // });
  // console.log("pars from course " + JSON.stringify(Pars));

  useEffect(() => {
    frontGolferTotals();
  },[]);

  const [redo, setRedo] = useState(false);

  const changeHandler = (e) => {
    e.preventDefault();
    var name = e.target.name;
    var ovalue = e.target.value;
    var value = e.target.value;
    var id = e.target.id;
    var playerid = Number(id)+1;
    // loadPars();
    if (golfers[id].golfername.length === 0) {
      e.target.value = "";
      console.log("line 58 ");
      return;
    }
    if (isNaN(+value)) {
      value = "0";
      alert(
        "NOT A NUMBER VALUE SET TO ZERO ==> " + name + " " + id + " " + ovalue
      );
      e.target.value = value;
    }
      console.log("value 62 "+ value + "="+name+"="+id);
                      
    if (name === "h01") golfers[id].h01 = value;
    if (name === "h02") golfers[id].h02 = value;
    if (name === "h03") golfers[id].h03 = value;
    if (name === "h04") golfers[id].h04 = value;
    if (name === "h05") golfers[id].h05 = value;
    if (name === "h06") golfers[id].h06 = value;
    if (name === "h07") golfers[id].h07 = value;
    if (name === "h08") golfers[id].h08 = value;
    if (name === "h09") golfers[id].h09 = value;
    console.log("line 72 "+golfers[id].h01);
    window.displayad = false;
    if ((name === "h08") && (playerid === window.numberplayers)) {
      window.displayad = true;
      window.gaction = 0;
      ShowAd();
    } else {
      window.displayad = true;
      window.gaction = 0;
    }
    DisplayAd();
    frontGolferTotals();
    saveToLocalStorage();
    setRedo(!redo);
  };

  return (
    <div>
      <div className="center">
        <table
          id="frontscores"
          style={{
            width: "17.5rem",
            padding: "0.0rem 1.35rem",
            marginLeft: "auto",
            marginRight: "auto",
            border: "3px solid purple",
          }}
        >
          <tbody>
            <tr>
              <th colSpan="2" style={{ fontSize: "0.65em" }}>
                Front Nine
              </th>
              <th>H01</th>
              <th>H02</th>
              <th>H03</th>
              <th>H04</th>
              <th>H05</th>
              <th>H06</th>
              <th>H07</th>
              <th>H08</th>
              <th>H09</th>
              <th>Total</th>
            </tr>
            <tr id="fontpars">
              <th colSpan="2">
                {Pars["frontcourse"]}
              </th>
              <th>{Pars[1]}</th>
              <th>{Pars[2]}</th>
              <th>{Pars[3]}</th>
              <th>{Pars[4]}</th>
              <th>{Pars[5]}</th>
              <th>{Pars[6]}</th>
              <th>{Pars[7]}</th>
              <th>{Pars[8]}</th>
              <th>{Pars[9]}</th>
              <th>{Pars["ftot"]}</th>
            </tr>
            {golfers.map((golfer, index) => (
              // for (let i=0;  i < 4; i++) {
              <tr key={golfer.id}>
                <td colSpan="2">
                  <input
                    readOnly
                    style={{
                      // width: "5.15em",
                      width: "4.9rem",
                      textAlign: "left",
                      backgroundColor: "#d3f8d3",
                    }}
                    tabIndex="-1"
                    value={golfers[index].golfername}
                  />
                </td>
                <td>
                  <input
                    name="h01"
                    value={golfers[index].h01}
                    id={index}
                    className={classNames((golfers[index].h01==="4") ? "rndborder" : "sqrborder")}
                    ref={(el) => {
                      frtH01Ref.current[index] = el;
                    }}
                    type="text"
                    inputMode="numeric"
                    size="2"
                    min="0"
                    maxLength="2"
                    max="99"
                    pattern="[0-9]{2}"
                    // onKeyPress={keyPressHandler}
                    onChange={changeHandler}
                    >
                    </input>
                </td>
                <td>
                  <input
                    name="h02"
                    value={golfers[index].h02}
                    id={index}
                    ref={(e2) => {
                      frtH02Ref.current[index] = e2;
                    }}
                    type="text"
                    inputMode="numeric"
                    size="2"
                    min="0"
                    maxLength="2"
                    max="99"
                    pattern="[0-9]{2}"
                    style={{ textAlign: "center" }}
                    // onBlur={keyPressHandler}
                    onChange={changeHandler}
                  />
                </td>
                <td>
                  <input
                    name="h03"
                    value={golfers[index].h03}
                    id={index}
                    ref={(e2) => {
                      frtH03Ref.current[index] = e2;
                    }}
                    type="text"
                    inputMode="numeric"
                    size="2"
                    min="0"
                    maxLength="2"
                    max="99"
                    pattern="[0-9]{2}"
                    style={{ textAlign: "center" }}
                    // onBlur={keyPressHandler}
                    onChange={changeHandler}
                  />
                </td>
                <td>
                  <input
                    name="h04"
                    value={golfers[index].h04}
                    id={index}
                    ref={(e2) => {
                      frtH04Ref.current[index] = e2;
                    }}
                    type="text"
                    inputMode="numeric"
                    size="2"
                    min="0"
                    maxLength="2"
                    max="99"
                    pattern="[0-9]{2}"
                    style={{ textAlign: "center" }}
                    // onBlur={keyPressHandler}
                    onChange={changeHandler}
                  />
                </td>
                <td>
                  <input
                    name="h05"
                    value={golfers[index].h05}
                    id={index}
                    ref={(e2) => {
                      frtH05Ref.current[index] = e2;
                    }}
                    type="text"
                    inputMode="numeric"
                    size="2"
                    min="0"
                    maxLength="2"
                    max="99"
                    pattern="[0-9]{2}"
                    style={{ textAlign: "center" }}
                    // onBlur={keyPressHandler}
                    onChange={changeHandler}
                  />
                </td>
                <td>
                  <input
                    name="h06"
                    value={golfers[index].h06}
                    id={index}
                    ref={(e2) => {
                      frtH06Ref.current[index] = e2;
                    }}
                    type="text"
                    inputMode="numeric"
                    size="2"
                    min="0"
                    maxLength="2"
                    max="99"
                    pattern="[0-9]{2}"
                    style={{ textAlign: "center" }}
                    // onBlur={keyPressHandler}
                    onChange={changeHandler}
                  />
                </td>
                <td>
                  <input
                    name="h07"
                    value={golfers[index].h07}
                    id={index}
                    ref={(e2) => {
                      frtH07Ref.current[index] = e2;
                    }}
                    type="text"
                    inputMode="numeric"
                    size="2"
                    min="0"
                    maxLength="2"
                    max="99"
                    pattern="[0-9]{2}"
                    style={{ textAlign: "center" }}
                    // onBlur={keyPressHandler}
                    onChange={changeHandler}
                  />
                </td>
                <td>
                  <input
                    name="h08"
                    value={golfers[index].h08}
                    id={index}
                    ref={(e2) => {
                      frtH08Ref.current[index] = e2;
                    }}
                    type="text"
                    inputMode="numeric"
                    size="2"
                    min="0"
                    maxLength="2"
                    max="99"
                    pattern="[0-9]{2}"
                    style={{ textAlign: "center" }}
                    // onBlur={keyPressHandler}
                    onChange={changeHandler}
                  />
                </td>
                <td>
                  <input
                    name="h09"
                    value={golfers[index].h09}
                    id={index}
                    ref={(e2) => {
                      frtH09Ref.current[index] = e2;
                    }}
                    type="text"
                    inputMode="numeric"
                    size="2"
                    min="0"
                    maxLength="2"
                    max="99"
                    pattern="[0-9]{2}"
                    style={{ textAlign: "center" }}
                    // onBlur={keyPressHandler}
                    onChange={changeHandler}
                  />
                </td>
                <td>
                  <input
                    readOnly
                    type="text"
                    id={index}
                    total="total"
                    style={{ width: "2.4em", backgroundColor: "#d3f8d3" }}
                    value={frontGolferTotal[index]}
                  />
                </td>
              </tr>
            ))}
            {/* } */}
            <tr style={{ height: "1.5" }}>
              <td colSpan="2" className="outside">
                <input
                  readOnly
                  style={{
                    width: "4.90rem",
                    // width: "6.155em",
                    // width: "6.65em",
                    textAlign: "center",
                    backgroundColor: "#d3f8d3",
                  }}
                  tabIndex="-1"
                  value="Totals ==>"
                />
              </td>
              <td className="outside">
                <input
                  readOnly
                  // id={index}
                  // ref={(el) => (frtTotH01Ref.current[index] = el)}
                  style={{ backgroundColor: "#d3f8d3" }}
                  tabIndex="-1"
                  // value={newTotals01}
                  value={frontHoleTotal[0]}
                />
              </td>
              <td className="outside">
                <input
                  readOnly
                  style={{ backgroundColor: "#d3f8d3" }}
                  tabIndex="-1"
                  value={frontHoleTotal[1]}
                />
              </td>
              <td className="outside">
                <input
                  readOnly
                  style={{ backgroundColor: "#d3f8d3" }}
                  tabIndex="-1"
                  value={frontHoleTotal[2]}
                />
              </td>
              <td className="outside">
                <input
                  readOnly
                  style={{ backgroundColor: "#d3f8d3" }}
                  tabIndex="-1"
                  value={frontHoleTotal[3]}
                />
              </td>
              <td className="outside">
                <input
                  readOnly
                  style={{ backgroundColor: "#d3f8d3" }}
                  tabIndex="-1"
                  value={frontHoleTotal[4]}
                />
              </td>
              <td className="outside">
                <input
                  readOnly
                  style={{ backgroundColor: "#d3f8d3" }}
                  tabIndex="-1"
                  value={frontHoleTotal[5]}
                />
              </td>
              <td className="outside">
                <input
                  readOnly
                  style={{ backgroundColor: "#d3f8d3" }}
                  tabIndex="-1"
                  value={frontHoleTotal[6]}
                />
              </td>
              <td className="outside">
                <input
                  readOnly
                  style={{ backgroundColor: "#d3f8d3" }}
                  tabIndex="-1"
                  value={frontHoleTotal[7]}
                />
              </td>
              <td className="outside">
                <input
                  readOnly
                  style={{ backgroundColor: "#d3f8d3" }}
                  tabIndex="-1"
                  value={frontHoleTotal[8]}
                />
              </td>
              <td className="outside">
                <input
                  readOnly
                  tabIndex="-1"
                  value={frontHoleTotal[9]}
                  style={{ width: "2.4em", backgroundColor: "#d3f8d3" }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FrontNine; //exporting a component make it reusable and this is the beauty of react
