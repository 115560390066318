import golfers from "../../../Data/golfers";
import backGolferTotal from "../../../Globals/BackGolferTotals";
import backHoleTotal from "../../../Globals/BackHoleTotals";

const backGolferTotals = () => {
  clearTotals();
  bholeTotals();
  bgolferTotals();
  backTotal();
  totalTotals();
  changeToBlanks();
}

const clearTotals = () => {
  for (let g = 0; g < 10; g++) {
    backHoleTotal[g] = 0;
  }

  for (let i = 0; i < 4; i++) {
    backGolferTotal[i] = 0;
    golfers[i].btotal = 0;
  }
};

const bholeTotals = () => {
  for (let i = 0; i < 4; i++) {
      backHoleTotal[0] = backHoleTotal[0] + Number(golfers[i].h10);
      backHoleTotal[1] = backHoleTotal[1] + Number(golfers[i].h11);
      backHoleTotal[2] = backHoleTotal[2] + Number(golfers[i].h12);
      backHoleTotal[3] = backHoleTotal[3] + Number(golfers[i].h13);
      backHoleTotal[4] = backHoleTotal[4] + Number(golfers[i].h14);
      backHoleTotal[5] = backHoleTotal[5] + Number(golfers[i].h15);
      backHoleTotal[6] = backHoleTotal[6] + Number(golfers[i].h16);
      backHoleTotal[7] = backHoleTotal[7] + Number(golfers[i].h17);
      backHoleTotal[8] = backHoleTotal[8] + Number(golfers[i].h18);
  }
}
const bgolferTotals = () => {
  for (let i = 0; i < 4; i++) {
    backGolferTotal[i] = backGolferTotal[i] + Number(golfers[i].h10);
    backGolferTotal[i] = backGolferTotal[i] + Number(golfers[i].h11);
    backGolferTotal[i] = backGolferTotal[i] + Number(golfers[i].h12);
    backGolferTotal[i] = backGolferTotal[i] + Number(golfers[i].h13);
    backGolferTotal[i] = backGolferTotal[i] + Number(golfers[i].h14);
    backGolferTotal[i] = backGolferTotal[i] + Number(golfers[i].h15);
    backGolferTotal[i] = backGolferTotal[i] + Number(golfers[i].h16);
    backGolferTotal[i] = backGolferTotal[i] + Number(golfers[i].h17);
    backGolferTotal[i] = backGolferTotal[i] + Number(golfers[i].h18);
    golfers[i].btotal =  backGolferTotal[i];
  }
}

const backTotal = () => {
  window.bholetotal = 0
  backHoleTotal[9] = 0
  for (let i = 0; i < 4; i++) {
    window.bholetotal = window.bholetotal + golfers[i].btotal; 
    backHoleTotal[9] = backHoleTotal[9] + golfers[i].btotal;
  }
}
const totalTotals = () => {
  for (let i = 0; i < 4; i++) {
    golfers[i].total = golfers[i].btotal + golfers[i].ftotal; 
  } 
}

const changeToBlanks = () => {
  for (let g = 0; g < 10; g++) {
    if (backHoleTotal[g] === 0) {
      backHoleTotal[g] = "";
    }
  }
  for (let i = 0; i < 4; i++) {
    if (backGolferTotal[i] === 0) {
      backGolferTotal[i] = "";
    }
  }
};

export default backGolferTotals;
