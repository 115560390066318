import "./Course.css";
import React, { useState } from "react";
import golfers from "../../../components/Data/golfers";
import saveToLocalStorage from "../Utils/Library/SaveToLocalStorage";
import newScoreMethod from "../Utils/Library/newScoreMethod";
import reloadGolfers from "../Utils/Library/ReloadGolfers";
import frontGolferTotals from "../Utils/Library/FrontGolferTotals";
import backGolferTotals from "../Utils/Library/BackGolferTotals";
import Pars from "../../Globals/Pars";
import { getByDisplayValue } from "@testing-library/react";
import setRandomIds from "../Utils/Library/SetRandomIds";
import setGlobals from "../Utils/Library/SetGlobals";

const Course = ({Clear, DisplayPlayer}) => {
const [redo, setRedo] = useState(false);
// console.log("before course display  "+ golfers[0].gamedate);
  const changeNameHandler = (e) => {
    e.preventDefault();
    const value = e.target.value;
    window.coursename = value;
    golfers.map( ( golfers ) => 
      (golfers.coursename = value));
      // console.log("course "+ golfers[0].coursename);
      window.coursename = golfers[0].coursename;
      window.coursedataname = golfers[0].coursename.replace(/. /g,"");
      // console.log("coursedataname " + window.coursedataname);
    // saveToLocalStorage();
    // setRedo(!redo);
    loadPars();
    setGlobals();
    DisplayPlayer();
  };

  const changeDateHandler = (e) => {
    e.preventDefault();
    // const value = e.target.value;
    golfers.map( ( golfers ) => 
      (golfers.gamedate = e.target.value));
    // setRedo(!redo);
    // console.log("before data "+golfers[0].gamedate);
    const data = localStorage.getItem("game"+golfers[0].gamedate);
    // console.log("data >>>"+data+"<<<");
    if ( data !== null ) {
      // console.log("in data "+golfers[0].gamedate);
      reloadGolfers(data);
      newScoreMethod(golfers[0].scoremethod);
      // todo add team and globals here
      frontGolferTotals();
      backGolferTotals();
      setGlobals();
      saveToLocalStorage()
      // DisplayPlayer();
    } else {
      setRandomIds();
    }
    DisplayPlayer();
  };

  const changeTeeTimeHandler = (e) => {
    e.preventDefault();
    // const value = e.target.value;
    golfers.map( ( golfers ) => 
      (golfers.teetime = e.target.value));
    // setRedo(!redo);
    DisplayPlayer();
  };

  const changeScoreMethodHandler = (e) => {
    e.preventDefault();
    const value = e.target.value;
    golfers.map( ( golfers ) => (golfers.scoremethod = value));
    newScoreMethod(value);
    // setRandomIds();
    window.scrollamount = 650;
    saveCourse();
  };

  const saveCourse = () => {
    saveToLocalStorage();
    DisplayPlayer();
    // setRedo(!redo);
  }

  const loadPars = () => {
    // console.log("coursename " + window.coursename);
    let pars="";
    switch (window.coursedataname) {
      case 'StAndrews':
        pars = require("../../Data/CourseInfo/StAndrews/par").default;
        return pars;       
      case 'IronHorse':
        pars = require("../../Data/CourseInfo/IronHorse/par").default;
        return pars;       
      case 'SykesLady':
        pars = require("../../Data/CourseInfo/SykesLady/par").default;
        return pars;       
      default:
          return null;
    }
  }

  const setPars =  (par) => {
    // let par = pars;
    if (par === null) { return;}
    Pars[0]=par[0].tees;
    Pars[1]=par[0].h01;
    Pars[2]=par[0].h02;
    Pars[3]=par[0].h03;
    Pars[4]=par[0].h04;
    Pars[5]=par[0].h05;
    Pars[6]=par[0].h06;
    Pars[7]=par[0].h07;
    Pars[8]=par[0].h08;
    Pars[9]=par[0].h09;
    Pars["ftot"]=par[0].ftot;
    Pars[10]=par[0].h10;
    Pars[11]=par[0].h11;
    Pars[12]=par[0].h12;
    Pars[13]=par[0].h13;
    Pars[14]=par[0].h14;
    Pars[15]=par[0].h15;
    Pars[16]=par[0].h16;
    Pars[17]=par[0].h17;
    Pars[18]=par[0].h18;
    Pars["btot"]=par[0].btot;
    Pars["frontcourse"]=par[0].frontcourse;
    Pars["backcourse"]=par[0].backcourse;
    var temp = par[0].ftot;
    var teearr = par[0].tees.split(",");
    window.teetimearr = golfers[0].teetime.split("-");
    }
   
  setPars(loadPars());
  let i =0;
  return (
    <div className="course_center">
      <div>
        <div className="course-container">
          <form>
            <div
              className="center"
              style={{
                width: "auto",
                display: "table",
              }}
            >
              <div className="container" >
                <div className="row" key={golfers.id}>
                  <div className="column,  course-labels">Date:</div>
                  <div className="column">
                    <input
                      style={{
                        color: "blue",
                        opacity: "1",
                        backgroundColor: "white",
                      }}
                      type="date"
                      id="date"
                      name="date"
                      autoFocus
                      value={golfers[i].gamedate}
                      onChange={changeDateHandler}
                    />
                  </div>
                </div>
                <div style={{ marginBottom: "0.3rem" }}></div>
                <div className="row">
                  <div className="column, course-labels">Course: </div>
                  <div className="column">
                    {/* <input */}
                    <select
                      className="colume-input"
                      type="text"
                      name="coursename"
                      value={golfers[i].scoursename}
                      placeholder="St.Andrews,IronHorse"
                      onChange={changeNameHandler}
                    >
                      <option value = "{window.coursename}"></option>
                      <option value = "St. Andrews">St. Andrews</option>
                      <option value = "IronHorse">IronHorse</option>
                      <option value = "SykesLady">SykesLady</option>
                    </select> 
                    </div>
                </div>
                <div style={{ marginBottom: "0.3rem" }}></div>
                <div className="row">
                  <div className="column, course-labels">Tee-Time:&nbsp;</div>
                  <div className="column">
                    <input
                      className="colume-input"
                      type="text"
                      name="teetime"
                      value={golfers[i].teetime}
                      placeholder="[01|10]-[hh:mm]-[tee]"
                      onChange={changeTeeTimeHandler}
                    />
                  </div>
                </div>
                <div style={{ marginBottom: "0.3rem" }}></div>
                <div className="row">
                  <div className="column, course-labels">
                    Score Method:&nbsp;
                  </div>
                  <div className="column">
                    {/* <input */}
                    <select
                      className="colume-input"
                      type="text"
                      name="scoremethod"
                      value={golfers[i].scoremethod}
                      placeholder="Stableford,Stroke,Match"
                      onChange={changeScoreMethodHandler}
                    >
                      <option value = "{window.scorename}"></option>
                      <option value = "Stableford">Stableford</option>
                      <option value = "Stroke">Stroke</option>
                      <option value = "Match">Match</option>
                    </select> 
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Course;
