import React from "react";
import "../../../Mobile/styles.css";

const Footer = () => (
  <footer className="centerfooter">
    &copy; 2019 John Sereno
  </footer>
);

export default Footer;
